<template>
  <div>
    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icofont-close js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>

    <header
      class="site-navbar js-sticky-header site-navbar-target"
      role="banner"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-6 col-lg-2">
            <h1 class="mb-0 site-logo">
              <router-link to="/" class="mb-0">Shadheeskumar</router-link>
            </h1>
          </div>

          <div class="col-12 col-md-10 d-none d-lg-block">
            <nav
              class="site-navigation position-relative text-right"
              role="navigation"
            >
              <ul
                class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block"
              >
                <li><router-link to="/" class="nav-link">Home</router-link></li>
                <li>
                  <router-link to="/who-am-i" class="nav-link"
                    >Who Am I</router-link
                  >
                </li>
                <li>
                  <router-link to="/what-i-do" class="nav-link"
                    >What I Do</router-link
                  >
                </li>
                <!-- <li>
                  <router-link to="/blogs" class="nav-link">Blog</router-link>
                </li> -->
                <li>
                  <router-link to="/contact-me" class="nav-link"
                    >Contact Me</router-link
                  >
                </li>
              </ul>
            </nav>
          </div>

          <div
            class="col-6 d-inline-block d-lg-none ml-md-0 py-3"
            style="position: relative; top: 3px"
          >
            <a
              href="#"
              class="burger site-menu-toggle js-menu-toggle"
              data-toggle="collapse"
              data-target="#main-navbar"
            >
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  beforeRouteEnter(next) {
    this.$loadScript("assets/vendor/jquery/jquery.min.js").then(() => {
      this.$loadScript(
        "assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
      ).then(() => {
        this.$loadScript(
          "assets/vendor/jquery.easing/jquery.easing.min.js"
        ).then(() => {
          this.$loadScript("assets/vendor/aos/aos.js").then(() => {
            this.$loadScript(
              "assets/vendor/owl.carousel/owl.carousel.min.js"
            ).then(() => {
              this.$loadScript(
                "assets/vendor/jquery-sticky/jquery.sticky.js"
              ).then(() => {
                this.$loadScript("assets/js/main.js").then(() => {
                  next();
                });
              });
            });
          });
        });
      });
    });
  },
  beforeCreate() {
    this.$loadScript("assets/vendor/jquery/jquery.min.js").then(() => {
      this.$loadScript(
        "assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
      ).then(() => {
        this.$loadScript(
          "assets/vendor/jquery.easing/jquery.easing.min.js"
        ).then(() => {
          this.$loadScript("assets/vendor/aos/aos.js").then(() => {
            this.$loadScript(
              "assets/vendor/owl.carousel/owl.carousel.min.js"
            ).then(() => {
              this.$loadScript(
                "assets/vendor/jquery-sticky/jquery.sticky.js"
              ).then(() => {
                this.$loadScript("assets/js/main.js").then(() => {});
              });
            });
          });
        });
      });
    });
  },
  beforeMount() {
    this.$loadScript("assets/vendor/jquery/jquery.min.js").then(() => {
      this.$loadScript(
        "assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
      ).then(() => {
        this.$loadScript(
          "assets/vendor/jquery.easing/jquery.easing.min.js"
        ).then(() => {
          this.$loadScript("assets/vendor/aos/aos.js").then(() => {
            this.$loadScript(
              "assets/vendor/owl.carousel/owl.carousel.min.js"
            ).then(() => {
              this.$loadScript(
                "assets/vendor/jquery-sticky/jquery.sticky.js"
              ).then(() => {
                this.$loadScript("assets/js/main.js").then(() => {});
              });
            });
          });
        });
      });
    });
  },
  name: "Header",
};
</script>

<style>
</style>
<template>
  <main id="main">
    <section class="hero-section inner-page">
      <div class="wave">
        <vue-particles class="particles" color="#dedede"></vue-particles>
        <svg
          width="1920px"
          height="400px"
          viewBox="0 0 1920 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Apple-TV"
              transform="translate(0.000000, -402.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row justify-content-left">
              <div class="col-md-9 text-left hero-text">
                <h1
                  data-aos="fade-up"
                  data-aos-delay=""
                  style="margin-top: 150px"
                >
                  Providing Perfect Solutions to Your Problems
                </h1>
                <p class="mb-5" data-aos="fade-up" data-aos-delay="100">
                  Explore What I Do and How I Provide Solutions To You
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                >
                  <a
                    target="_blank"
                    href="https://resume.shadheeskumar.com/"
                    class="btn btn-outline-white"
                    >Download Resume</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 ml-auto order-2">
            <h2 class="mb-4"></h2>
            <p class="mb-6 text-justify">
              Technology has paved the way to many great wonders we have today.
              Hey, even this website you’re looking at right now was made
              possible due to great technology. But it’s not going to stop
              becoming greater and you shouldn’t too.
            </p>
            <br />
            <p class="mb-6 text-justify">
              Have an idea lingering around in your head? Or can’t stop thinking
              of a solution to a problem you know technology can help solve? But
              can’t seem to find the person who can help you in your journey?
            </p>
            <br />
            <p class="mb-6 text-justify">
              Well fret not, you are at the right place!
            </p>
            <br />
            <p class="mb-6 text-justify">This is where I come in.</p>
            <br />
            <p class="mb-6 text-justify">
              The not-your-typical Software Engineer who loves crafting the
              perfect solution to a problem as much as I love my coffee. And
              tea.
            </p>
            <br />
            <!-- <p class="mb-6 text-justify">
              I’m Shadheeskumar (but you can call me Shadd) and I specialize in
              finding and creating solutions using technology. With over 10
              years of software development experience in multiple fields, I
              have worked with many teams and brands to craft the perfect
              solution to make our lives easier (with technology of course).
              Together we can find the perfect execution and the perfect
              solution to the perfect problem you have.
            </p> -->
            <p class="mb-6 text-justify">
              I’m Shadheeskumar (but you can call me Shadd) and I specialize in
              finding and creating solutions using technology. With my
              unwavering passion for technology and more than 10 years of
              experience in software development across diverse industries, I
              have become adept at recognizing and delivering cutting-edge
              solutions. Through fruitful collaborations with various teams and
              renowned brands, I have sharpened my ability to create customized
              and impactful resolutions. Whether leading the initiation of new
              ventures or revitalizing outdated systems, I take pride in
              successfully strategizing and implementing projects that surpass
              expectations and adapt to ever-changing requirements. Allow me to
              harness my expertise to propel your organization towards
              transformative outcomes.
            </p>
          </div>
          <div class="col-md-6" data-aos="fade-right">
            <img
              src="assets/img/Who_Am_I.jpeg"
              alt="Image"
              class="img-fluid"
              style="border-radius: 20px"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-7 mb-5">
            <div class="jobSection">
              <h4
                class="section-heading row justify-content-center text-center"
              >
                Work Experiences
              </h4>

              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-company">Fefifo</p>
                  <p class="text-center job-title">Senior Technology Lead</p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 03/2022 – Present
                  </p>
                  <p class="text-center job-location">
                    <i class="icofont-location-pin"></i> Kuala Lumpur, Malaysia
                  </p>

                  <p class="text-center job-experience-description">
                    Key Qualifications & Responsibilities
                  </p>
                  <p class="text-center job-desc">
                    - Headed a cross-functional team of developers, business
                    analysts, and UI/UX professionals to drive the successful
                    execution of projects.
                  </p>
                  <p class="text-center job-desc">
                    - Pioneered the development and launch of the innovative
                    Magic Bean App, contributing to its market success and user
                    adoption.
                  </p>
                  <p class="text-center job-desc">
                    - Led the maintenance and enhancement of the Digitally
                    Distributed Farming Network (DDFN) 1.0 software, ensuring
                    its optimal performance and reliability.
                  </p>
                  <p class="text-center job-desc">
                    - Spearheaded the planning and development efforts for the
                    highly anticipated Digitally Distributed Farming Network
                    (DDFN) 2.0, leveraging your technical expertise to drive its
                    successful implementation.
                  </p>
                  <p class="text-center job-desc">
                    - Orchestrated the seamless transition of DDFN 1.0 to the
                    next-generation DDFN 2.0, ensuring a smooth user experience
                    and minimizing disruption to operations.
                  </p>
                  <p class="text-center job-desc">
                    - Pioneered the establishment and leadership of the IoT
                    division at Fefifo, spearheading the strategic direction and
                    successful implementation of IoT projects.
                  </p>
                  <p class="text-center job-desc">
                    - Provided invaluable guidance and mentorship to team
                    members, fostering their professional growth and enhancing
                    their technical capabilities.
                  </p>

                  <p class="text-center job-experience-description">
                    Key Achievement:
                  </p>
                  <p class="text-center job-desc">
                    - Successfully led a cross-functional team of developers,
                    business analysts, and UI/UX professionals in the successful
                    development and launch of the Magic Bean App, garnering
                    positive user feedback and generating significant interest
                    within the target market.
                  </p>
                  <p class="text-center job-desc">
                    - Achieved exceptional results in maintaining and enhancing
                    DDFN 1.0, significantly reducing system downtime by ~40% and
                    improving overall system speed and stability by ~80% hence
                    improving user satisfaction.
                  </p>
                  <p class="text-center job-desc">
                    - Led the planning and development efforts for DDFN 2.0,
                    resulting in an innovative and highly scalable software
                    solution that exceeded user expectations and delivered ~500%
                    increase in speed alongside new looks and ease of use.
                  </p>
                  <p class="text-center job-desc">
                    - Orchestrated the seamless transition of DDFN 1.0 to the
                    next-generation DDFN 2.0, minimizing disruption to
                    operations and ensuring a smooth user experience.
                  </p>
                  <p class="text-center job-desc">
                    - Pioneered the establishment of the IoT division at Fefifo,
                    leading the strategic direction and successfully delivering
                    innovative IoT projects tailored for agricultural
                    applications. These projects have been executed within
                    budget and timeline, laying the foundation for revenue
                    growth and positioning Fefifo as a leader in agricultural
                    IoT solutions.
                  </p>
                  <p class="text-center job-desc">
                    - Recognized for providing exceptional guidance and
                    mentorship to team members, resulting in increased employee
                    satisfaction and improvement in the team's technical
                    capabilities.
                  </p>
                </div>
              </div>

              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-company">Accenture</p>
                  <p class="text-center job-title">
                    Manager (Tools Development)
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 11/2021 – 03/2022
                  </p>
                  <p class="text-center job-company">
                    Entropia - Part of Accenture
                  </p>
                  <p class="text-center job-title">
                    Manager (Tools Development)
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 06/2021 – 11/2021
                  </p>
                  <p class="text-center job-title">
                    Senior Associate (Tools Development)
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 12/2019 – 05/2021
                  </p>
                  <p class="text-center job-location">
                    <i class="icofont-location-pin"></i> Kuala Lumpur, Malaysia
                  </p>

                  <p class="text-center job-experience-description">
                    Key Qualifications & Responsibilities
                  </p>
                  <p class="text-center job-desc">
                    - Spearheaded the successful execution of IoT projects,
                    leveraging cutting-edge technologies to develop innovative
                    solutions for diverse industries.
                  </p>
                  <p class="text-center job-desc">
                    - Led the development of mobile applications, ensuring
                    seamless user experiences and adherence to industry best
                    practices.
                  </p>
                  <p class="text-center job-desc">
                    - Played a pivotal role in web development projects,
                    delivering high-quality solutions that met client
                    requirements and exceeded expectations.
                  </p>
                  <p class="text-center job-desc">
                    - Successfully engaged with clients from various industries,
                    including MDEC, Sime Darby Property, Etika, UEM Sunrise,
                    Pepsi, and AIA, to understand their unique business
                    requirements and deliver tailored solutions.
                  </p>
                  <p class="text-center job-desc">
                    - Acted as a liaison between clients and the development
                    team, ensuring effective communication, managing
                    expectations, and delivering projects within specified
                    timelines and budgets.
                  </p>
                  <p class="text-center job-desc">
                    - Leveraged client feedback to drive continuous innovation
                    and improvement in our tools development processes,
                    resulting in enhanced efficiency and client-centric
                    solutions.
                  </p>

                  <p class="text-center job-experience-description">
                    Key Achievement:
                  </p>
                  <p class="text-center job-desc">
                    - Orchestrated the successful execution of IoT projects,
                    delivering innovative solutions that leveraged cutting-edge
                    technologies and addressing specific business needs for
                    diverse industries.
                  </p>
                  <p class="text-center job-desc">
                    - Led the development of mobile applications, incorporating
                    user-centric design principles and industry best practices.
                  </p>
                  <p class="text-center job-desc">
                    - Proactively leveraged client feedback to drive continuous
                    innovation and improvement in the tools development process,
                    resulting in enhanced efficiency, streamlined workflows, and
                    the introduction of client-centric features.
                  </p>
                </div>

                <div class="row align-items-stretch">
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img src="assets/img/brands/mdec.png" alt="MDEC" />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img src="assets/img/brands/etika.png" alt="Etika" />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/uem-sunrise.png"
                      alt="UEM Sunrise"
                    />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/fintech-booster.png"
                      alt="Fintech Booster"
                    />
                  </div>
                  <div class="col-lg-4 mb-4 mb-lg-0">
                    <img
                      src="assets/img/brands/aia.png"
                      alt="AIA Philam Life"
                    />
                  </div>
                  <div class="col-lg-4 mb-4 mb-lg-0">
                    <img src="assets/img/brands/pepsi.png" alt="Pepsi" />
                  </div>
                  <div class="col-lg-4 mb-4 mb-lg-0">
                    <img
                      src="assets/img/brands/sime-darby.png"
                      alt="Sime Darby"
                    />
                  </div>
                </div>
              </div>

              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-company">Everise Technology</p>
                  <p class="text-center job-title">
                    Fullstack IoT, Mobile App & Web Developer
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 09/2018 – 05/2019
                  </p>
                  <p class="text-center job-location">
                    <i class="icofont-location-pin"></i> Kedah, Malaysia
                  </p>

                  <p class="text-center job-experience-description">
                    Key Qualifications & Responsibilities
                  </p>
                  <p class="text-center job-desc">
                    - Spearheaded the development of mobile apps and IoT
                    solutions, utilizing cutting-edge technologies to deliver
                    innovative and user-centric applications.
                  </p>
                  <p class="text-center job-desc">
                    - Developed mobile apps for both Android and iOS platforms,
                    ensuring seamless user experiences and adherence to industry
                    standards and guidelines.
                  </p>
                  <p class="text-center job-desc">
                    - Provided supervision and guidance to a team of 2 interns,
                    overseeing their project work and facilitating their
                    professional growth.
                  </p>
                  <p class="text-center job-desc">
                    - Developed full stack web applications, encompassing both
                    frontend and backend development, to deliver robust and
                    scalable web solutions.
                  </p>
                  <p class="text-center job-desc">
                    - Managed server infrastructure, including Linux-based
                    systems, ensuring optimal performance and uptime for web and
                    IoT applications.
                  </p>
                  <p class="text-center job-desc">
                    - Led a major IoT project, involving hardware design,
                    software design, and the development of mobile and web
                    applications for monitoring and controlling IoT devices.
                  </p>

                  <p class="text-center job-experience-description">
                    Key Achievement:
                  </p>
                  <p class="text-center job-desc">
                    - Pioneered the adoption of more modern frameworks and
                    languages, such as Ionic, Angular and NodeJS, within the
                    organization, transitioning from outdated technologies like
                    VB.net to embrace more efficient and contemporary
                    development practices.
                  </p>
                  <p class="text-center job-desc">
                    - Led the initiative to modernize the technology stack,
                    which significantly improved development efficiency, code
                    maintainability, and overall system performance.
                  </p>
                  <p class="text-center job-desc">
                    - Implemented best practices and coding standards, leading
                    to improved code quality, easier collaboration among team
                    members, and reduced time for bug fixes and feature
                    implementations.
                  </p>
                  <p class="text-center job-desc">
                    - Played a pivotal role in modernizing the development
                    process and elevating the technological capabilities of the
                    organization, positioning it for future growth and
                    innovation.
                  </p>
                </div>
              </div>

              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-company">
                    S&S Innovation Technologies
                  </p>
                  <p class="text-center job-title">Technology Consultant</p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 04/2019 – 12/2019
                  </p>
                  <p class="text-center job-title">
                    Fullstack Software Engineer with Server Administration
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 04/2015 – 08/2018
                  </p>
                  <p class="text-center job-location">
                    <i class="icofont-location-pin"></i> Penang, Malaysia
                  </p>

                  <p class="text-center job-experience-description">
                    Key Qualifications & Responsibilities
                  </p>
                  <p class="text-center job-desc">
                    - Demonstrated exceptional proficiency in managing server
                    infrastructure based on Linux, ensuring seamless operations,
                    optimal performance, and robust security for web and IoT
                    applications.
                  </p>
                  <p class="text-center job-desc">
                    - Spearheaded the development of mobile apps and played a
                    key role in IoT projects, leveraging cutting-edge
                    technologies to deliver innovative and user-centric
                    solutions that met and exceeded client expectations.
                  </p>
                  <p class="text-center job-desc">
                    - Led the successful preparation and maintenance of the
                    groundbreaking Smart Parking Gadget, an advanced device that
                    revolutionized parking management practices in Malaysia,
                    offering unparalleled efficiency and convenience.
                  </p>
                  <p class="text-center job-desc">
                    - Developed high-quality Android apps, employing best
                    practices and adhering to industry standards to deliver
                    seamless and engaging user experiences on mobile platforms.
                  </p>
                  <p class="text-center job-desc">
                    - Created sophisticated web monitoring interfaces that
                    provided real-time data visualization and intuitive control
                    functionalities, empowering businesses to monitor and manage
                    critical systems effectively.
                  </p>
                  <p class="text-center job-desc">
                    - Gained extensive expertise in working with ROSIM WPSD-140S
                    sensor and WDC-400x wireless data collector, harnessing
                    their capabilities to enable precise monitoring and accurate
                    data collection for various applications.
                  </p>
                  <p class="text-center job-desc">
                    - Leveraged the powerful features of ROSIM Parkview software
                    to enhance data analysis, management, and reporting
                    processes, contributing to informed decision-making and
                    improved operational efficiency.
                  </p>
                  <p class="text-center job-desc">
                    - Achieved a notable milestone by introducing the pioneering
                    Smart Parking Gadget, the first-ever smart parking device
                    adopted by Majlis Bandaraya Pulau Pinang, establishing a new
                    standard for smart parking solutions in Malaysia.
                  </p>

                  <p class="text-center job-experience-description">
                    Key Achievement:
                  </p>
                  <p class="text-center job-desc">
                    - Successfully implemented server infrastructure management
                    practices, ensuring seamless operations, optimal
                    performance, and robust security for web and IoT
                    applications, resulting in enhanced system reliability and
                    client satisfaction.
                  </p>
                  <p class="text-center job-desc">
                    - Spearheaded the development of mobile apps and made
                    significant contributions to IoT projects, leveraging
                    cutting-edge technologies and innovative approaches to
                    deliver solutions that exceeded client expectations,
                    resulting in improved user experiences and positive business
                    outcomes.
                  </p>
                  <p class="text-center job-desc">
                    - Led the successful preparation and maintenance of the
                    groundbreaking Smart Parking Gadget, a pioneering device
                    that transformed parking management practices in Malaysia.
                    Its unparalleled efficiency and convenience revolutionized
                    the industry and positioned the company as a leader in smart
                    parking solutions.
                  </p>
                  <p class="text-center job-desc">
                    - Achieved a significant milestone by introducing the
                    pioneering Smart Parking Gadget, the first-ever smart
                    parking device adopted by Majlis Bandaraya Pulau Pinang,
                    setting a new standard for smart parking solutions in
                    Malaysia, and establishing the company as an industry leader
                    in innovative parking management technology.
                  </p>
                </div>

                <div class="row align-items-stretch">
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/mbpp.png"
                      alt="Majlis Bandaraya Pulau Pinang"
                    />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/spg_device.png"
                      alt="SPG Device"
                    />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/spg_device_2.png"
                      alt="SPG Device"
                    />
                  </div>
                  <div class="col-lg-3 mb-3 mb-lg-0">
                    <img
                      src="assets/img/brands/spg_reload.png"
                      alt="SPG with Reload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-7 mb-5">
            <div class="jobSection">
              <h4
                class="section-heading row justify-content-center text-center"
              >
                Education
              </h4>
              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-company">
                    Universiti Teknikal Malaysia Melaka
                  </p>
                  <p class="text-center job-title">
                    Bachelor’s Degree (Artificial Intelligence)
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 09/2015 – 02/2019
                  </p>
                  <p class="text-center job-location">
                    <i class="icofont-location-pin"></i> Malacca, Malaysia
                  </p>

                  <p class="text-center job-experience-description">Course</p>
                  <p class="text-center job-desc">Artificial Intelligence</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="maps">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 mr-auto text-center mb-5 mb-md-0">
            <h2>Want to connect and have a chat?</h2>
          </div>
          <div class="col-md-12 text-center">
            <p>
              <router-link to="/contact-me" class="btn">Contact Me</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Who Am I",
};
</script>

<style>
</style>
<template>
  <main id="main" v-if="blog.length > 0">
    <section class="hero-section inner-page">
      <div class="wave">
        <vue-particles class="particles" color="#dedede"></vue-particles>
        <svg
          width="1920px"
          height="400px"
          viewBox="0 0 1920 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Apple-TV"
              transform="translate(0.000000, -402.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-md-10 text-center hero-text">
                <h1
                  data-aos="fade-up"
                  data-aos-delay=""
                  style="margin-top: 150px"
                >
                  {{ blog[0].header }}
                </h1>
                <p class="mb-5" data-aos="fade-up" data-aos-delay="100">
                  {{ blog[0].written_on }} &bullet; By
                  <a class="text-white">{{ blog[0].written_by }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="site-section mb-4">
      <div class="blog">
        <div class="container">
          <div class="row">
            <div class="col-md-8 blog-content">
              <p class="lead mb-5 text-justify">
                {{ blog[0].sub_header }}
              </p>

              <div class="row mb-5">
                <div class="col-md-12 row justify-content-center text-center">
                  <img
                    :src="blog[0].blog_image"
                    alt="Image"
                    class="img-fluid"
                    style="border-radius: 20px"
                  />
                </div>
              </div>

              <div class="text-justify" style="border-bottom-style: solid">
                <span v-html="blog[0].context"></span>
              </div>
            </div>
            <div class="col-md-4 sidebar">
              <div class="sidebar-box">
                <img
                  src="assets/img/Shadheeskumar_square.png"
                  alt="Shadheeskumar"
                  class="img-fluid mb-4"
                />
                <h3>About The Author</h3>
                <p class="text-justify">
                  I’m Shadheeskumar (but you can call me Shadd) and I specialize
                  in finding and creating solutions using technology. With over
                  10 years of software development experience in multiple
                  fields, I have worked with many teams and brands to craft the
                  perfect solution to make our lives easier (with technology of
                  course). Together we can find the perfect execution and the
                  perfect solution to the perfect problem you have.
                </p>
                <p>
                  <router-link to="/who-am-i" class="btn btn-primary btn-sm"
                    >Explore Who I Am?</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="maps">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 mr-auto text-center mb-5 mb-md-0">
            <h2>Want to connect and have a chat?</h2>
          </div>
          <div class="col-md-12 text-center">
            <p>
              <router-link to="/contact-me" class="btn">Contact Me</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import config from "config";

export default {
  name: "Blog",
  data() {
    return {
      blog_id: this.$route.query.blog,
      blog: [],
    };
  },
  methods: {
    async getBlog(blog_id) {
      try {
        const url = config.apiUrl + "blogs/article";
        var params = {
          blog_id: blog_id,
        };
        const response = await axios.post(url, params);
        const results = response.data.result.data;
        this.blog = results.map((blog) => ({
          blog_id: blog.blog_id,
          blog_image: blog.blog_image,
          written_on: blog.written_on,
          written_by: blog.written_by,
          header: blog.header,
          sub_header: blog.sub_header,
          context: blog.context,
        }));
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
  },
  mounted() {
    this.getBlog(this.blog_id);
  },
};
</script>

<style>
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import LoadScript from "vue-plugin-load-script";
import VueParticles from 'vue-particles'

const app = createApp(App);

// const { $, jQuery } = require('jquery');
// global.$ = $;
// global.jQuery = jQuery;
// Vue.prototype.jQuery = jQuery;
// Vue.prototype.$ = $;

import $ from "jquery"
window.$ = $

app.use(router)
app.use(LoadScript);
app.use(VueParticles);
app.mount("#app");

// createApp(App).use(router).mount('#app')

<template>
  <footer class="footer" role="contentinfo">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mb-4 mb-md-0">
          <h3>About Shadheeskumar</h3>
          <p class="text-justify">
            With a passion for technology and a decade of experience in software
            development across various industries, I excel at identifying and
            delivering innovative solutions. Collaborating with diverse teams
            and renowned brands, I have honed my skills in crafting tailored and
            impactful solutions. From spearheading the inception of new projects
            to revamping legacy systems, I have successfully planned and
            executed projects to meet evolving needs and exceed expectations.
            Let me leverage my expertise to drive transformative outcomes for
            your organization.
          </p>
          <p class="social">
            <a
              href="https://api.whatsapp.com/send/?phone=60103998871&text=Hey+Shadd%21+Are+you+up+for+a+chat?"
              target="_blank"
              ><span class="icofont-whatsapp"></span
            ></a>
            <a href="https://www.instagram.com/shadheeskumar/" target="_blank"
              ><span class="icofont-instagram"></span
            ></a>
            <a
              href="https://www.linkedin.com/in/shadheeskumar-thinakaran/"
              target="_blank"
              ><span class="icofont-linkedin"></span
            ></a>
            <a href="https://github.com/Shadheeskumar" target="_blank"
              ><span class="icofont-github"></span
            ></a>
          </p>
        </div>
      </div>

      <div class="row justify-content-center text-center">
        <div class="col-md-7">
          <p class="copyright">
            Copyright &copy; Shadheeskumar. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>
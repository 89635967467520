import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import WhoAmI from '../views/WhoAmI.vue'
import WhatIDo from '../views/WhatIDo.vue'
import Blog from '../views/Blog.vue'
import Contact from '../views/Contact.vue'
import ReadBlog from '../views/ReadBlog.vue'
import Pricing from '../views/Pricing.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/who-am-i',
    name: 'WhoAmI',
    component: WhoAmI
  },
  {
    path: '/what-i-do',
    name: 'WhatIDo',
    component: WhatIDo
  },
  {
    path: '/blogs',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/contact-me',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/blog',
    name: 'ReadBlog',
    component: ReadBlog,
    props: true
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  // {
  //   path: '*', redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router

<template>
  <div>
    <section class="hero-section" id="hero">
      <div class="wave">
        <vue-particles class="particles" color="#dedede"></vue-particles>
        <svg
          width="1920px"
          height="400px"
          viewBox="0 0 1920 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Apple-TV"
              transform="translate(0.000000, -402.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 hero-text-image">
            <div class="row">
              <div class="col-lg-7 text-center text-lg-left">
                <h1 data-aos="fade-right">Shadheeskumar Thinakaran</h1>
                <p class="mb-5" data-aos="fade-right" data-aos-delay="100">
                  A Connoisseur of Technology
                </p>
                <p
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                >
                  <a
                    target="_blank"
                    href="https://resume.shadheeskumar.com/"
                    class="btn btn-outline-white"
                    >Download Resume</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <main id="main">
      <section class="section">
        <div class="container">
          <div class="row justify-content-center text-center mb-5">
            <div class="col-md-5" data-aos="fade-up">
              <h2 class="section-heading">So Who Am I?</h2>
            </div>
          </div>

          <div class="row justify-content-center text-center">
            <div class="col-md-7 mb-5" data-aos="fade-up">
              <!-- <p>
                The not-your-typical Software Engineer who loves crafting the
                perfect solution to a problem as much as I love my coffee. And
                tea.
              </p> -->
              <p>
                With my unwavering passion for technology and more than 10 years
                of experience in software development across diverse industries,
                I have become adept at recognizing and delivering cutting-edge
                solutions. Through fruitful collaborations with various teams
                and renowned brands, I have sharpened my ability to create
                customized and impactful resolutions. Whether leading the
                initiation of new ventures or revitalizing outdated systems, I
                take pride in successfully strategizing and implementing
                projects that surpass expectations and adapt to ever-changing
                requirements. Allow me to harness my expertise to propel your
                organization towards transformative outcomes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center" data-aos="fade-up">
          <p>
            <router-link to="/who-am-i" class="btn"
              >Explore Who I Am?</router-link
            >
          </p>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="row justify-content-center text-center mb-5">
            <div class="col-md-5" data-aos="fade-up">
              <h2 class="section-heading">Expertize In</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="">
              <div class="feature-1 text-center">
                <div class="wrap-icon icon-1">
                  <span class="icon">
                    <i class="icofont-stock-mobile"></i>
                  </span>
                </div>
                <h3 class="mb-3">Mobile App Development</h3>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
              <div class="feature-1 text-center">
                <div class="wrap-icon icon-1">
                  <span class="icon">
                    <i class="icofont-dashboard-web"></i>
                  </span>
                </div>
                <h3 class="mb-3">Web Development</h3>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
              <div class="feature-1 text-center">
                <div class="wrap-icon icon-1">
                  <span class="icon">
                    <i class="icofont-micro-chip"></i>
                  </span>
                </div>
                <h3 class="mb-3">IoT Development</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center" data-aos="fade-up">
          <p>
            <router-link to="/what-i-do" class="btn"
              >Explore What I Do?</router-link
            >
          </p>
        </div>
      </section>

      <!-- <section class="section">
        <div class="container" style="margin-bottom: -100px">
          <div class="row justify-content-center text-center mb-5">
            <div class="col-md-5" data-aos="fade-up">
              <h2 class="section-heading">Featured Blogs</h2>
            </div>
          </div>

          <div class="row mb-5" data-aos="fade-up">
            <div class="col-md-4" v-for="b in blogs" :key="b.blog_id">
              <div class="post-entry">
                <router-link
                  :to="'/blog?blog=' + b.blog_id"
                  class="d-block mb-4"
                >
                  <img :src="b.blog_image" alt="Image" class="img-fluid" />
                </router-link>
                <div class="post-text">
                  <span class="post-meta"
                    >{{ b.written_on }} &bullet; By {{ b.written_by }}</span
                  >
                  <h3>
                    <router-link
                      :to="'/blog?blog=' + b.blog_id"
                      class="text-justify"
                      >{{ b.header }}</router-link
                    >
                  </h3>
                  <p class="text-justify">
                    {{ b.sub_header }}
                  </p>
                  <p>
                    <router-link
                      :to="'/blog?blog=' + b.blog_id"
                      class="readmore"
                      >Read More</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center" data-aos="fade-up">
          <p>
            <router-link to="/blogs" class="btn"
              >Explore More Blogs!</router-link
            >
          </p>
        </div>
      </section> -->

      <section class="maps">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12 mr-auto text-center mb-5 mb-md-0">
              <h2>Want to connect and have a chat?</h2>
            </div>
            <div class="col-md-12 text-center">
              <p>
                <router-link to="/contact-me" class="btn"
                  >Contact Me</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import config from "config";

export default {
  name: "Home",
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    async getBlogs() {
      try {
        const url = config.apiUrl + "blogs/list";
        const response = await axios.post(url);
        const results = response.data.result.data;
        this.blogs = results.map((blog) => ({
          blog_id: blog.blog_id,
          blog_image: blog.blog_image,
          written_on: blog.written_on,
          written_by: blog.written_by,
          header: blog.header,
          sub_header: this.snip_subHeader(blog.sub_header),
        }));
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
    snip_subHeader(subHeader) {
      var string = subHeader;
      var length = 255;
      return string.substring(0, length) + " ...";
    },
  },
  mounted() {
    this.getBlogs();
  },
};
</script>

<style>
</style>
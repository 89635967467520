<template>
  <main id="main">
    <section class="hero-section inner-page">
      <div class="wave">
        <vue-particles class="particles" color="#dedede"></vue-particles>
        <svg
          width="1920px"
          height="400px"
          viewBox="0 0 1920 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Apple-TV"
              transform="translate(0.000000, -402.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row justify-content-left">
              <div class="col-md-9 text-left hero-text">
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1 data-aos="fade-up" data-aos-delay="">
                  Want to connect and have a chat?
                </h1>
                <p class="mb-5" data-aos="fade-up" data-aos-delay="100">
                  Choose the most convenient way for you to connect with me
                  today
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                >
                  <a
                    target="_blank"
                    href="https://resume.shadheeskumar.com/"
                    class="btn btn-outline-white"
                    >Download Resume</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="contactMe">
        <h4 class="section-heading row justify-content-center text-center">
          Let's Chat!
        </h4>
        <div class="row align-items-stretch social">
          <div class="col-lg-3 mb-3 mb-lg-0">
            <img src="assets/img/social/whatsapp.png" alt="" />
            <p class="text-center">WhatsApp</p>
            <p style="text-align: center">
              <a
                href="https://api.whatsapp.com/send/?phone=60103998871&text=Hey+Shadd%21+Are+you+up+for+a+chat?"
                target="_blank"
                >Click here to chat</a
              >
            </p>
          </div>
          <div class="col-lg-3 mb-3 mb-lg-0">
            <img src="assets/img/social/phone.png" alt="" />
            <p class="text-center">Phone</p>
            <p style="text-align: center">
              <a href="tel:+6010-399-8871" target="_blank">+6010 399 8871</a>
            </p>
          </div>
          <div class="col-lg-3 mb-3 mb-lg-0">
            <img src="assets/img/social/email.png" alt="" />
            <p class="text-center">Email</p>
            <p style="text-align: center">
              <a href="mailto:hello@shadheeskumar.com" target="_blank"
                >hello@shadheeskumar.com</a
              >
            </p>
          </div>
          <div class="col-lg-3 mb-3 mb-lg-0">
            <img src="assets/img/social/instagram.png" alt="" />
            <p class="text-center">Instagram</p>
            <p style="text-align: center">
              <a href="https://www.instagram.com/shadheeskumar/" target="_blank"
                >DM Me on Instagram!</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="maps">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 mr-auto text-center mb-5 mb-md-0">
            <h2>You Can Also Meet Me At</h2>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Contact Me",
};
</script>

<style>
</style>
<template>
  <main id="main">
    <section class="hero-section inner-page">
      <div class="wave">
        <vue-particles class="particles" color="#dedede"></vue-particles>
        <svg
          width="1920px"
          height="400px"
          viewBox="0 0 1920 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Apple-TV"
              transform="translate(0.000000, -402.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row justify-content-left">
              <div class="col-md-9 text-left hero-text">
                <h1
                  data-aos="fade-up"
                  data-aos-delay=""
                  style="margin-top: 150px"
                >
                  The Technicality to How my Solutions are Born
                </h1>
                <p class="mb-5" data-aos="fade-up" data-aos-delay="100">
                  Ready to dive deep into getting to know what I do, how I do it
                  and get a little technical
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                >
                  <a
                    target="_blank"
                    href="https://resume.shadheeskumar.com/"
                    class="btn btn-outline-white"
                    >Download Resume</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-7 mb-5">
            <h2 class="section-heading">The Tech Stack I Use</h2>
            <p>
              These are the Tech Stack I expertize in to cook up a Perfect
              Solution for You
            </p>
          </div>
        </div>

        <div class="techStack">
          <h4 class="section-heading-h4 row justify-content-center text-center">
            Mobile Development
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img
                src="assets/img/tech-stack/ionic.png"
                alt="Ionic Framework"
              />
              <p class="text-center">Ionic Framework</p>
            </div>
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img src="assets/img/tech-stack/flutter.png" alt="Flutter" />
              <p class="text-center">Flutter</p>
            </div>
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            Web Development
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/svelte.png" alt="SvelteKit" />
              <p class="text-center">SvelteKit</p>
            </div>
            <!-- <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/angular.png" alt="Angular" />
              <p class="text-center">Angular</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/html.png" alt="HTML" />
              <p class="text-center">HTML</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/css.png" alt="CSS" />
              <p class="text-center">CSS</p>
            </div> -->
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/js.png" alt="JavaScript" />
              <p class="text-center">JavaScript</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/ts.png" alt="TypeScript" />
              <p class="text-center">TypeScript</p>
            </div>
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            Backend Development
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img src="assets/img/tech-stack/nodejs.png" alt="NodeJS" />
              <p class="text-center">NodeJS</p>
            </div>
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img src="assets/img/tech-stack/laravel.png" alt="Laravel" />
              <p class="text-center">Laravel</p>
            </div>
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            Database
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img src="assets/img/tech-stack/mysql.png" alt="MySQL" />
              <p class="text-center">MySQL</p>
            </div>
            <div class="col-lg-6 mb-6 mb-lg-0">
              <img src="assets/img/tech-stack/mongodb.png" alt="MongoDB" />
              <p class="text-center">MongoDB</p>
            </div>
            <!-- <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/dynamodb.png" alt="DynamoDB" />
              <p class="text-center">DynamoDB</p>
            </div> -->
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            Cloud
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src="assets/img/tech-stack/aws.png"
                alt="Amazon Web Services (AWS)"
              />
              <p class="text-center">AWS</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/appwrite.png" alt="Appwrite" />
              <p class="text-center">Appwrite</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src="assets/img/tech-stack/firebase.png"
                alt="Google Firebase"
              />
              <p class="text-center">Firebase</p>
            </div>
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            IoT Development
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/arduino.png" alt="Arduino" />
              <p class="text-center">Arduino</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src="assets/img/tech-stack/mqtt.png"
                alt="Message Queuing Telemetry Transport"
              />
              <p class="text-center">MQTT</p>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img src="assets/img/tech-stack/iotcore.png" alt="AWS IoT Core" />
              <p class="text-center">AWS IoT Core</p>
            </div>
          </div>

          <h4 class="section-heading-h4 row justify-content-center text-center">
            Server Environments
          </h4>
          <div class="row align-items-stretch">
            <div class="col-lg-3 mb-3 mb-lg-0">
              <img src="assets/img/tech-stack/ubuntu.png" alt="Ubuntu" />
              <p class="text-center">Ubuntu</p>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0">
              <img src="assets/img/tech-stack/docker.png" alt="Docker" />
              <p class="text-center">Docker</p>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0">
              <img
                src="assets/img/tech-stack/truenas.png"
                alt="TrueNAS Scale"
              />
              <p class="text-center">TrueNAS Scale</p>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0">
              <img src="assets/img/tech-stack/nginx.png" alt="NGINX" />
              <p class="text-center">NGINX</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-7 mb-5">
            <div class="jobSection">
              <h4
                class="section-heading row justify-content-center text-center"
              >
                Personal Projects
              </h4>
              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-title">
                    Real-time Human Detection and Alert System for the Forest.
                    (EcoWatcher)
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 03/2018 – 08/2018
                  </p>

                  <p class="text-center job-experience-description">
                    Project Description
                  </p>
                  <p class="text-center job-desc">
                    EcoWatcher (EWa) is a smart camera system which is built for
                    the forest. It consists of hardware capable of performing
                    real-time human detection. The system uses Machine Learning
                    to detect humans based on features and captures the image if
                    a human is detected. This image is then sent to the
                    authorities. This system was built to help prevent illegal
                    poaching in the forest.
                  </p>
                </div>
              </div>

              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-title">
                    Deep Learning Powered IoT Integrated TrashBot
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 08/2017 – 12/2017
                  </p>

                  <p class="text-center job-experience-description">
                    Project Description
                  </p>
                  <p class="text-center job-desc">
                    Deep Learning Powered IoT (Internet of Things) Integrated
                    TrashBot (IIT) is a robot recycle bin that sorts landfill
                    waste from recyclable materials. IIT automates recycling at
                    the source using integrated robotics, computer vision, and
                    machine learning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="section">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-7 mb-5">
            <div class="jobSection">
              <h4
                class="section-heading row justify-content-center text-center"
              >
                Achievements
              </h4>
              <div class="row align-items-stretch">
                <div class="col-lg-12 mb-12 mb-lg-0">
                  <p class="text-center job-top"></p>
                  <p class="text-center job-title">
                    Champion of Innovate Malaysia Design Challenge
                  </p>
                  <p class="text-center job-date">
                    <i class="icofont-ui-calendar"></i> 2018
                  </p>

                  <p class="text-center job-desc">
                    - Champion for Design Challenge (WCS)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="maps">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 mr-auto text-center mb-5 mb-md-0">
            <h2>Want to connect and have a chat?</h2>
          </div>
          <div class="col-md-12 text-center">
            <p>
              <router-link to="/contact-me" class="btn">Contact Me</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "What I Do",
};
</script>

<style>
</style>